import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledSlideTwo = styled.div<{
  productColor: any;
  textColor: string;
}>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;

  .S2__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
  }
  .S2__slogan {
    white-space: nowrap;
    text-align: center;
    z-index: 2;
    margin: 0;
    margin-bottom: 30px;
    color: ${({ textColor }) => textColor};
  }

  .S2__title {
    z-index: 2;
    text-align: left;
    color: ${({ textColor }) => textColor};
  }

  .S2__title-b {
    margin-left: 0.2em;
    font-family: "Nimbus San Con D";
    font-weight: 400;
  }

  .S2__image {
    margin-bottom: 20px;
    display: none;
  }

  button {
    margin-top: 60px;
  }

  .S2__btn {
    margin-top: 30px;
  }
  .S2__btn:hover {
    background-color: ${({ productColor }) => productColor};
    border-color: ${({ productColor }: { productColor: any }) => productColor};
    a {
      color: ${theme.text.white};
    }
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    .S2__title {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        margin-left: 0;
      }
    }
  }
`;
