import React, { useEffect } from "react";

import { StyledBackgrounds } from "./Backgrounds.styled";
import { WPImage } from "@/components/WPImage/WPImage";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import ParticleImage from "@/components/ParticleImage/ParticleImage";
import { IWPImage } from "@/types/WPImage";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

interface Props {
  pageProps: {
    background: IWPImage;
    product_image: IWPImage;
    particle_images: IWPImage;
  };
}

const Backgrounds = ({ pageProps }: Props) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".bkg__container",
          pin: true,
          scrub: 0.2,
          start: "top top",
          end: "+=10000",
        },
      })
      .to(".bkg__product-image", {
        rotation: 360 * 2,
        duration: 1,
        ease: "none",
      });
  }, []);

  return (
    <StyledBackgrounds background={pageProps?.background}>
      <div className="main-inner">
        <div className="bkg__container">
          {pageProps?.particle_images && (
            <ParticleImage particleImage={pageProps?.particle_images} />
          )}
          {pageProps?.product_image && (
            <div className="bkg__product-image">
              <WPImage
                image={pageProps?.product_image}
                width={"850"}
                height={"850"}
              />
            </div>
          )}
        </div>
      </div>
    </StyledBackgrounds>
  );
};

export default Backgrounds;
