import gsap from "gsap";

export const GsapEffect = () => {
  // ScrollSmoother.create({
  //   content: "#sections",
  //   smooth: 1,
  // });

  //opacity scroll trigger
  gsap.to(".slide_1", {
    opacity: 0,
    ease: "none",
    scrollTrigger: {
      trigger: ".section01",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_2", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section00",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_2", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section01",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_3", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section01",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_3", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section02",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_4", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section02",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_4", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section03",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_5", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section03",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_5", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section04",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_6", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section04",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_6", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section05",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_7", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section05",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_7", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section06",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_8", {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".section06",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_8", {
    opacity: 0,
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section07",
      start: "center top",
      end: "bottom top",
      scrub: true,
    },
  });

  //display toggle scroll trigger
  gsap.to(".slide_1", {
    visibility: "hidden",
    ease: "none",
    scrollTrigger: {
      trigger: ".section01",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_2", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section00",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_2", {
    visibility: "hidden",
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section01",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_3", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section01",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_3", {
    visibility: "hidden",
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section02",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_4", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section02",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_4", {
    visibility: "hidden",
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section03",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_5", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section03",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_5", {
    visibility: "hidden",
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section04",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_6", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section04",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_6", {
    visibility: "hidden",
    ease: "none",
    immediateRender: false,
    scrollTrigger: {
      trigger: ".section05",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_7", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section05",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_7", {
    visibility: "hidden",
    ease: "none",
    scrollTrigger: {
      trigger: ".section06",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_8", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section06",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_8", {
    visibility: "hidden",
    ease: "none",
    scrollTrigger: {
      trigger: ".section07",
      start: "bottom top",
      end: "bottom top",
      scrub: true,
    },
  });
  gsap.to(".slide_9", {
    visibility: "visible",
    ease: "none",
    scrollTrigger: {
      trigger: ".section07",
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
};
