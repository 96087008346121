import { IWPImage } from "@/types/WPImage";
import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledBackgrounds = styled.div<{ background: IWPImage }>`
  width: 150%;
  height: 100%;
  min-height: 100vh;
  background-image: url(${({ background }) => background?.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .bkg__container {
    position: relative;
    max-width: 1440px;
    padding: 0px 100px;
    margin: 0px auto;
    height: 100%;
    width: 100%;
  }

  .bkg__product-image {
    position: absolute;

    top: 0px;
    right: -250px;
  }

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    width: 250%;

    .bkg__product-image {
      right: 100px;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    width: 350%;
    .bkg__product-image {
      // display: none;
    }
  }
`;
