import type { NextPage } from "next";
import * as api from "../utils/woocommerce/api";
import { StyledIndexBody } from "../styles/index.styles";
import { useSelector } from "react-redux";
import SwipeComp from "@/components/SwipeComp/SwipeComp";

import FrontPageHero from "@/components/FrontPageHero/FrontPageHero";
import TextBanner from "@/components/TextBanner/TextBanner";
import Slide1 from "@/components/FrontPageSlides/Slide1/Slide1";
import Slide2 from "@/components/FrontPageSlides/Slide2/Slide2";
import Slide3 from "@/components/FrontPageSlides/Slide3/Slide3";
import Slide4 from "@/components/FrontPageSlides/Slide4/Slide4";
import Slide5 from "@/components/FrontPageSlides/Slide5/Slide5";
import Slide6 from "@/components/FrontPageSlides/Slide6/Slide6";
import Gsap from "@/components/Gsap/Gsap";

interface Props {
  products: [{}];
  pageProps: [
    {
      acf: any;
    }
  ];
  options: {
    acf: any;
  };
}

const Home: NextPage<Props> = ({ pageProps, options }) => {
  const showAgeCheck = useSelector((state: any) => {
    return state.cart.showAgeCheck;
  });
  if (showAgeCheck) return <></>;



  return (
    <>
      {/* <FrontPageHero pageProps={pageProps[0].acf} /> */}
      {/* <TextBanner pageProps={options.acf.text_banner} /> */}
      <Gsap
        heroProps={pageProps[0].acf.hero}
        pageProps={pageProps[0].acf.slider}
       brandInfoProps={pageProps[0].acf.brand_information}
      />
    </>
  );
};

export async function getStaticProps() {
  const pageProps = await api.products.getStartPage();
  const options = await api.products.getOptions();

  return { props: { pageProps, options } };
}

export default Home;
