import React, { useEffect } from "react";
import { WPImage } from "../WPImage/WPImage";
import {
  StyledParticleImage,
  StyledParticleProduct,
} from "./ParticleImage.styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

type Props = {
  particleImage: any;
  productPage?: boolean;
};

const ParticleImage = ({ particleImage, productPage }: Props) => {
  const {
    image_1,
    image_2,
    image_3,
    image_4,
    image_5,
    image_6,
    image_7,
    image_8,
    image_9,
    image_10,
  } = particleImage;
  return (
    <>
      {!productPage ? (
        <StyledParticleImage>
          <div className="particle-images">
            {image_1 && (
              <div className="images" id="image-1">
                <WPImage image={image_1} height={"80"} width={"80"} />
              </div>
            )}
            {image_2 && (
              <div className="images" id="image-2">
                <WPImage image={image_2} height={"50"} width={"50"} />
              </div>
            )}
            {image_3 && (
              <div className="images" id="image-3">
                <WPImage image={image_3} height={"30"} width={"30"} />
              </div>
            )}
            {image_4 && (
              <div className="images" id="image-4">
                <WPImage image={image_4} height={"70"} width={"70"} />
              </div>
            )}
            {image_5 && (
              <div className="images" id="image-5">
                <WPImage image={image_5} height={"40"} width={"40"} />
              </div>
            )}
            {image_6 && (
              <div className="images" id="image-6">
                <WPImage image={image_6} height={"30"} width={"30"} />
              </div>
            )}
            {image_7 && (
              <div className="images" id="image-7">
                <WPImage image={image_7} height={"90"} width={"90"} />
              </div>
            )}
            {image_8 && (
              <div className="images" id="image-8">
                <WPImage image={image_8} height={"50"} width={"50"} />
              </div>
            )}
            {image_9 && (
              <div className="images" id="image-9">
                <WPImage image={image_9} height={"30"} width={"30"} />
              </div>
            )}
            {image_10 && (
              <div className="images" id="image-10">
                <WPImage image={image_10} height={"70"} width={"70"} />
              </div>
            )}
          </div>
        </StyledParticleImage>
      ) : (
        <StyledParticleProduct>
          {image_1 && (
            <div className="particle-right" id="product-particle1">
              <WPImage image={image_1} height={"80"} width={"80"} />
            </div>
          )}
          {image_2 && (
            <div className="particle-right" id="product-particle2">
              <WPImage image={image_2} height={"50"} width={"50"} />
            </div>
          )}
          {image_3 && (
            <div className="particle-right" id="product-particle3">
              <WPImage image={image_3} height={"30"} width={"30"} />
            </div>
          )}
          {image_4 && (
            <div className="particle-left" id="product-particle4">
              <WPImage image={image_4} height={"70"} width={"70"} />
            </div>
          )}
          {image_5 && (
            <div className="particle-left" id="product-particle5">
              <WPImage image={image_5} height={"40"} width={"40"} />
            </div>
          )}
          {image_6 && (
            <div className="particle-left" id="product-particle6">
              <WPImage image={image_6} height={"30"} width={"30"} />
            </div>
          )}
        </StyledParticleProduct>
      )}
    </>
  );
};

export default ParticleImage;
